import {defineStore} from 'pinia'
import {useNuxtApp} from "nuxt/app";
import {setLocale} from '@vee-validate/i18n';

export const useAuthStore = defineStore('auth', {
    // convert to a function
    state: () => ({
        isAuthenticated: null,
        abilities: [],
        userData: {
            'id': 0,
            'firstname': '',
            'lastname': '',
            'organisation_name': '',
            'organisation_id': 0,
            'organisation_token': '',
            'organisation_zapier_token': '',
            'organisation_shopify_token': '',
            'email': '',
            'image': '',
            'default_device_id': 0,
            'sound_id': null,
            'sound_path': null,
            'language': '',
            'currency': ''
        },
        branding: {},
        devices: [],
        shake: false,
        impersonate: false,
        soundsExist: false,
        eventTypes: [],
        subscription: {},
        soundsUpdated: false,
        devicesUpdated: false,
        homeDashboards: []
    }),

    actions: {

        //https://pinia.vuejs.org/core-concepts/actions.html#subscribing-to-actions
        async successfulLogin(redirectPath = '/', query = {}) {
            return await new Promise(async (resolve, reject) => {
                try {
                    this.isAuthenticated = true
                    await this.getAuthData()
                    navigateTo({
                        path: redirectPath,
                        query: query
                    })
                    resolve(true)
                } catch (error) {
                    reject(error)
                }
            })
        },

        async getAuthData(pass) {
            await $larafetch(useNuxtApp().$apiRoute("spa.authData"), {
                redirectIfNotAuthenticated: false
            }).then((response) => {
                this.setAuthData(response)
            }).catch(async (error) => {
                if (pass) return // prevent redirects on public dashboards
                await this.logout()
            })
        },

        setAuthData(authData) {
            this.isAuthenticated = true
            this.abilities = authData.abilities
            this.userData = authData.userData
            this.impersonate = authData.impersonate
            this.devices = authData.devices
            this.soundsExist = authData.soundsExist
            this.eventTypes = Object.values(authData.eventTypes)
            this.subscription = authData.subscription
            this.homeDashboards = authData.homeDashboards

            localStorage.setItem('isAuthenticated', 'true')
            localStorage.setItem('abilities', JSON.stringify(authData.abilities))
            localStorage.setItem('userData', JSON.stringify(authData.userData))

            if (authData.branding.organisation_branding === 'custom') {
                this.setBranding(authData.branding.organisation_branding_data)
            } else {
                this.clearBranding()
            }

            const {language} = JSON.parse(localStorage.getItem('userData'))
            useNuxtApp().$i18n.setLocale(language)
            // VeeValidate setLocale
            setLocale(language)
            useDayjs().locale(language)

            useNuxtApp().$sentrySetUser({email: authData.userData.email, id: authData.userData.id})
        },

        setBranding(data) {
            localStorage.setItem('organisation_branding', 1)
            localStorage.setItem('organisation_branding_data', JSON.stringify(data))
        },
        clearBranding() {
            localStorage.setItem('organisation_branding', null)
            localStorage.setItem('organisation_branding_data',null)
        },

        async logout(redirectPath = '/auth') {
            const {$apiRoute} = useNuxtApp()
            await $larafetch('/app/spa/logout', {
                method: "POST"
            })

            this.isAuthenticated = false
            this.abilities = []
            this.userData = null
            this.impersonate = false
            this.devices = []
            this.soundsExist = false
            this.eventTypes = []
            this.subscription = {}
            this.homeDashboards = []

            localStorage.setItem('isAuthenticated', null)
            localStorage.setItem('abilities', null)
            localStorage.setItem('userData', null)
            this.clearBranding()

            navigateTo({
                path: redirectPath
            })
        }
    }
})