import { default as activateQ6BnetdRFHMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/activate.vue?macro=true";
import { default as indexb7oCFBMI5lMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/index.vue?macro=true";
import { default as register_45completenrysTsnyNcMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/register-complete.vue?macro=true";
import { default as registerQj1hi4XOxaMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/register.vue?macro=true";
import { default as restoreCIBJihn7W2Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/restore.vue?macro=true";
import { default as indexmjBZz7q0XfMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93qWzIXDqvCTMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/[id].vue?macro=true";
import { default as indexUQh7aG2p3sMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93tnnH2yqCqXMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenZnH899tYIcMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/token.vue?macro=true";
import { default as demoIjptATyr9pMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/demo.vue?macro=true";
import { default as indexq43qLGcD4nMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/events/index.vue?macro=true";
import { default as impressumZd9I7KYxwSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/impressum.vue?macro=true";
import { default as indexZ1RAB5uPp8Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/index.vue?macro=true";
import { default as _91id_93enJtoOXgZvMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexxY6YdXtQntMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/deals/index.vue?macro=true";
import { default as _91id_93G2VHaxYiH9Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_938jJO85BDWVMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as HSLtoRGBYpWMNjsQTyMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as indexIVRH1xht9hMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/index.vue?macro=true";
import { default as RGBtoHSL3umEvTXpQmMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93YkSRGD8jZQMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexH5ix3TklFTMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_932BFGA4LMkzMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexdKhWMVSFIEMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/goals/index.vue?macro=true";
import { default as _91id_934Afilu03JSMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/groups/[id].vue?macro=true";
import { default as indexLbQp6LROh9Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/groups/index.vue?macro=true";
import { default as indexcOWjmPhhH5Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexmzkxG8yFEIMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_9387ddAxwXmXMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexS8cWbSOlUXMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93QhgFXAomWkMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/[id].vue?macro=true";
import { default as entriesDIHpLNw8icMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsk4j9SQPNQgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexvQRLMFERotMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/index.vue?macro=true";
import { default as indexOQnXjryH1MMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93R3kmL7eb4EMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexqd68NO6K7aMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93tynaL7NpC1Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexyZqpNVoPrFMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/hardware/index.vue?macro=true";
import { default as indexNtcNf8Zwo1Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_93BvrhCXCTvBMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexmp6SZG6K5ZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_938g3ajlS9mbMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexut9qag6TbgMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_9380f8pDIQpeMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/plans/[id].vue?macro=true";
import { default as indexVddIAFGjpCMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/plans/index.vue?macro=true";
import { default as releaselog8MIt2HwD2TMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/releaselog.vue?macro=true";
import { default as toolshtHE4aYcmPMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/tools.vue?macro=true";
import { default as _91id_93KnlNodYb4ZMeta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/users/[id].vue?macro=true";
import { default as indexUJPlKcEU35Meta } from "/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: "auth-activate",
    path: "/auth/activate",
    meta: activateQ6BnetdRFHMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/activate.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexb7oCFBMI5lMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/index.vue")
  },
  {
    name: "auth-register-complete",
    path: "/auth/register-complete",
    meta: register_45completenrysTsnyNcMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/register-complete.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerQj1hi4XOxaMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/register.vue")
  },
  {
    name: "auth-restore",
    path: "/auth/restore",
    meta: restoreCIBJihn7W2Meta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/auth/restore.vue")
  },
  {
    name: "buzzer",
    path: "/buzzer",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/buzzer/index.vue")
  },
  {
    name: "dashboards-id",
    path: "/dashboards/:id()",
    meta: _91id_93qWzIXDqvCTMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/[id].vue")
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: indexUQh7aG2p3sMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/index.vue")
  },
  {
    name: "dashboards-token",
    path: "/dashboards/token",
    meta: tokenZnH899tYIcMeta || {},
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/token.vue"),
    children: [
  {
    name: "dashboards-token-token",
    path: ":token()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/dashboards/token/[token].vue")
  }
]
  },
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/demo.vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/events/index.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/index.vue")
  },
  {
    name: "organisation-deals-id",
    path: "/organisation/deals/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/deals/[id].vue")
  },
  {
    name: "organisation-deals",
    path: "/organisation/deals",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/deals/index.vue")
  },
  {
    name: "organisation-devices-id",
    path: "/organisation/devices/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/[id].vue")
  },
  {
    name: "organisation-devices-devicegroup-id",
    path: "/organisation/devices/devicegroup/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/devicegroup/[id].vue")
  },
  {
    name: "organisation-devices-HSLtoRGB",
    path: "/organisation/devices/HSLtoRGB",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/HSLtoRGB.js")
  },
  {
    name: "organisation-devices",
    path: "/organisation/devices",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/index.vue")
  },
  {
    name: "organisation-devices-RGBtoHSL",
    path: "/organisation/devices/RGBtoHSL",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/devices/RGBtoHSL.js")
  },
  {
    name: "organisation-eventTypes-id",
    path: "/organisation/eventTypes/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/eventTypes/[id].vue")
  },
  {
    name: "organisation-eventTypes",
    path: "/organisation/eventTypes",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/eventTypes/index.vue")
  },
  {
    name: "organisation-goals-id",
    path: "/organisation/goals/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/goals/[id].vue")
  },
  {
    name: "organisation-goals",
    path: "/organisation/goals",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/goals/index.vue")
  },
  {
    name: "organisation-groups-id",
    path: "/organisation/groups/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/groups/[id].vue")
  },
  {
    name: "organisation-groups",
    path: "/organisation/groups",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/groups/index.vue")
  },
  {
    name: "organisation-settings",
    path: "/organisation/settings",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/settings/index.vue")
  },
  {
    name: "organisation-subscriptions",
    path: "/organisation/subscriptions",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/subscriptions/index.vue")
  },
  {
    name: "organisation-users-id",
    path: "/organisation/users/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/users/[id].vue")
  },
  {
    name: "organisation-users",
    path: "/organisation/users",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/organisation/users/index.vue")
  },
  {
    name: "sounds-id",
    path: "/sounds/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/[id].vue")
  },
  {
    name: "sounds",
    path: "/sounds",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/index.vue"),
    children: [
  {
    name: "sounds-index-entries",
    path: "entries",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/index/entries.vue")
  },
  {
    name: "sounds-index-playlists",
    path: "playlists",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/sounds/index/playlists.vue")
  }
]
  },
  {
    name: "system-devices",
    path: "/system/devices",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/devices/index.vue")
  },
  {
    name: "system-firmware-id",
    path: "/system/firmware/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/firmware/[id].vue")
  },
  {
    name: "system-firmware",
    path: "/system/firmware",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/firmware/index.vue")
  },
  {
    name: "system-hardware-id",
    path: "/system/hardware/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/hardware/[id].vue")
  },
  {
    name: "system-hardware",
    path: "/system/hardware",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/hardware/index.vue")
  },
  {
    name: "system-logs",
    path: "/system/logs",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/logs/index.vue")
  },
  {
    name: "system-notificationTemplates-id",
    path: "/system/notificationTemplates/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/notificationTemplates/[id].vue")
  },
  {
    name: "system-notificationTemplates",
    path: "/system/notificationTemplates",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/notificationTemplates/index.vue")
  },
  {
    name: "system-organisations-id",
    path: "/system/organisations/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/organisations/[id].vue")
  },
  {
    name: "system-organisations",
    path: "/system/organisations",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/organisations/index.vue")
  },
  {
    name: "system-plans-id",
    path: "/system/plans/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/plans/[id].vue")
  },
  {
    name: "system-plans",
    path: "/system/plans",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/plans/index.vue")
  },
  {
    name: "system-releaselog",
    path: "/system/releaselog",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/releaselog.vue")
  },
  {
    name: "system-tools",
    path: "/system/tools",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/tools.vue")
  },
  {
    name: "system-users-id",
    path: "/system/users/:id()",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/users/[id].vue")
  },
  {
    name: "system-users",
    path: "/system/users",
    component: () => import("/home/forge/dev-cloud.deal-buzzer.com/releases/20250324091720/client/pages/system/users/index.vue")
  }
]